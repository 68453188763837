import { Splide } from '@splidejs/splide';

class CardProduct extends HTMLElement {
  constructor() {
    super();
    this.image = this.getAttribute('background-check');
    this.info = this.querySelector('.js-card-info');
    this.splide = this.querySelector('.splide');
    if (this.splide) {
      this.slider = new Splide(this.splide, {
        type: 'loop',
        rewind: false,
        arrows: true,
        pagination: false,
        lazyLoad: false,
        drag: true,
        arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        classes: {
          arrow: 'splide__arrow cs-arrow',
          arrows: 'splide__arrows opacity-0 group-hover:opacity-100',
          // Add classes for pagination.
          page: 'splide__pagination-card-product' // each button
        },
        breakpoints: {
          768: {
            arrows: false,
            pagination: true
          }
        }
      });
    }
  }

  connectedCallback() {
    if (this.info) {
      if (this.image && this.image !== 'video') {
        // Only run checkBottomCorners if we have a valid image URL
        //this.checkBottomCorners(this.image, this.info);
      } else {
        // Default to black text if no valid image
        this.info.classList.add('lg:text-brand-primary-100');
        this.info.classList.remove('lg:text-white');
      }
    }

    this.querySelector('.splide__slide__container').addEventListener('mouseover', (event) => {
      if (!this.slider.state.is(Splide.STATES.IDLE)) {
        if (!this.slider.state.is(Splide.STATES.MOVING)) {
          this.slider.mount();
        }
      }
    });
  }

  checkBottomCorners(imageUrl, target) {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous'; // Required for CORS images
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      // Get bottom-left pixel data
      const bottomLeftPixel = ctx.getImageData(0, img.height - 1, 1, 1).data;

      // Get bottom-right pixel data
      const bottomRightPixel = ctx.getImageData(img.width - 1, img.height - 1, 1, 1).data;

      // Convert to grayscale
      const bottomLeftGray = 0.3 * bottomLeftPixel[0] + 0.59 * bottomLeftPixel[1] + 0.11 * bottomLeftPixel[2];
      const bottomRightGray = 0.3 * bottomRightPixel[0] + 0.59 * bottomRightPixel[1] + 0.11 * bottomRightPixel[2];

      // Check if grayscale values are closer to black or white
      const threshold = 128; // Midpoint between 0 and 255
      const bottomLeftIsBlack = bottomLeftGray < threshold;
      const bottomRightIsBlack = bottomRightGray < threshold;

      // Determine result
      let result = {};
      if (bottomLeftIsBlack && bottomRightIsBlack) {
        result = {
          areSame: true,
          color: 'black'
        };
      } else if (!bottomLeftIsBlack && !bottomRightIsBlack) {
        result = {
          areSame: true,
          color: 'white'
        };
      } else {
        result = {
          areSame: false,
          color: 'mixed'
        };
      }

      if (result.color == 'white') {
        target.classList.add('lg:text-brand-primary-100');
        target.classList.remove('lg:text-white');
      } else {
        target.classList.remove('lg:text-brand-primary-100');
        target.classList.add('lg:text-white');
      }
    };
  }
}

customElements.define('card-product', CardProduct);

// Usage:
